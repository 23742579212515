<template>
  <div class="histories">
    <div class="page_title_container flex justify-start w-full">
      Transactions
    </div>
    <div class="page_content">
      <div class="tab_container">
        tab
        <div id="people" />
      </div>
      <div class="detail_view">
        detail
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeSettings',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="scss">

</style>
